<template>
  <a-card :bordered="false">
    <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="版本号" name="version_sn">
        <a-input v-model:value="form.version_sn" placeholder="请输入更新的版本号"></a-input>
      </a-form-item>
      <a-form-item label="更新说明" name="note">
        <a-textarea v-model:value="form.note" placeholder="请输入更新说明" showCount :rows="5" :maxlength="100" />
      </a-form-item>
      <a-form-item label="数据包" name="url" :wrapper-col="{ span: 5 }">
        <a-upload v-model:fileList="fileList" name="file" accept='' class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="handleChange">
          <a-button>
            <UploadOutlined />
            点击上传
          </a-button>
          <span class="p-l-10 s_c999">只能上传正确的.apk文件</span>
        </a-upload>
      </a-form-item>
      <a-form-item label="更新状态" name="status">
        <a-radio-group v-model:value="form.status" required>
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="0">不启用</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
        <a-button type="primary" @click="onSubmit" :loading='loading'>
          保存
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
  import { UploadOutlined } from '@ant-design/icons-vue'
  import { onMounted, ref, reactive, toRefs, inject } from "vue";
  import { pdaVersionAdd, pdaVersionEdit, pdaVersionDetail } from '@/api/pda.js'
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  import { getToken } from "@/libs/util";
  import config from "@/config";
  import store from "@/store";
  const baseUrl = config.baseUrl[process.env.VUE_APP_type];
  const header = { apiAuth: getToken() };
  // 初始化默认筛选项数值
  let defForm = {
    url: "",
    version_sn: "",
    status: 1,
    note: "",
  };
  export default {
    components: {
      UploadOutlined
    },
    setup() {
      const ruleForm = ref();
      const form = ref({ ...defForm });
      const $route = inject("$route");
      const $router = inject("$router");
      const state = reactive({
        info: store.state.user.info,
        fileList: [],
        labelCol: { span: 2 },
        wrapperCol: { span: 10 },
        rules: {
          version_sn: [
            { required: true, message: "请输入更新的版本号", },
          ],
          note: [
            { required: true, message: "请输入更新说明", },
          ],
          url: [
            { required: true, message: "请上传更新的安装包", },
          ]
        }
      });
      //获取详情
      const getMore = async (id) => {
        const hide = message.loading("正在加载中...", 0);
        let res = await pdaVersionDetail({ id: id }).then((res) => res.data).catch((error) => error);
        if ($iscode(res)) {
          form.value = res.data;
          state.fileList = [{ url: res.data.url,name:res.data.version_sn+".apk" }]
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      //提交
      const onSubmit = () => {
        ruleForm.value.validate().then(async () => {
          const hide = message.loading("正在加载中...", 0);
          let res;
          if ($route.query.type == "add") {
            res = await pdaVersionAdd({ ...form.value }).then((res) => res.data).catch((error) => error);
          } else {
            res = await pdaVersionEdit({ ...form.value }).then((res) => res.data).catch((error) => error);
          }
          setTimeout(hide);
          if ($iscode(res)) {
            message.success(res.msg);
            setTimeout(() => {
              $router.go(-1);
            }, 1000);
          } else {
            message.error(res.msg);
          }
        }).catch((error) => {
          message.error("请完善表单！");
        });
      };
      const handleChange = (info) => {
        if (info.file.status === 'done') {
          state.fileList = info.fileList.splice(info.fileList.length - 1, 1)
          form.value.url = info.file.response.data.path
        }
        if (info.file.status === 'error') {
          message.error('文件上传失败！');
        }
        if (info.file.status === 'removed') {
          form.value.url = ''
        }
      }
      onMounted(() => {
        if ($route.query.type == "edit") {
          getMore($route.query.id);
        }
      });
      return {
        ...toRefs(state),
        form,
        ruleForm,
        baseUrl,
        header,
        onSubmit,
        handleChange,
      };
    },
  };
</script>